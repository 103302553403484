@import '../partials';

.story-grid {
  @include content-container;
  @include component;

  &__boundary {

    @include bp(lg) {
      display: flex;
      padding: 0 $component-spacing 0 0;
    }
  }

  &__image {
    flex-basis: rem(600);
    position: relative;
    align-self: flex-start;
    justify-content: flex-end;
    overflow: hidden;

    @include bp(lg) {
      width: 600px;
      margin-right: rem(12);
      height: auto;
      display: flex;
      max-height: none;
    }

    img {
      display: block;
      width: 100%;

      @include bp(lg) {
        width: auto;
      }
    }
  }

  &__image-credit {
    font-size: rem(8);
    color: $white;
    font-weight: $font-weight-normal;
    font-family: $sans-serif-body-font;
    position: absolute;
    bottom: 0;
    right: 0;
    padding:rem(8);
    text-shadow: 0 -2px 3px rgba(0, 0, 0, 0.5), 0 2px 3px rgba(0, 0, 0, 0.5);
  }

  &__content {
    flex-basis: rem(646);
    padding: rem(40) $mobile-padding;

    @include bp(md) {
      padding: rem(40) $component-spacing;
    }

    @include bp(lg) {
      padding-left: rem(60);
      padding-right: 0;

      .home & {
        padding-top: rem(60);
      }
    }
  }

  &__title {
    @include content-title;

    p {
      margin: 0;
    }
  }

  &__link {
    @include text-link-arrow-right;
  }

  &__cards {
    margin-top: rem(48);
    overflow: hidden;

    @include bp(sm) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include bp(lg) {
      display: block;
    }
  }

  &__card {
    width: 100%;
    margin-bottom: rem(20);
    display: flex;
    display: inline-block;

    @include bp(sm) {
      flex: 1 1 50%;

      &:nth-child(odd) {
        padding-right: rem(10);
      }

      &:nth-child(even) {
        padding-left: rem(10);
      }
    }

    @include bp(lg) {
      &:nth-child(odd) {
        padding-right: 0;
      }

      &:nth-child(even) {
        padding-left: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: rem(18) rem(24);
      color: $white;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @include overlay-gradient-slight;
    }

    &-headline {
      display: block;
      font-size: rem(18);
      font-weight: $font-weight-normal;
      font-style: italic;

      @include bp(sm) {
        font-size: rem(22);
      }
    }

    &-title {
      display: block;
      font-size: rem(25);
      font-weight: $font-weight-heavy;
      margin-top: rem(-5);

      @include bp(xs) {
        font-size: rem(32);
      }

      @include bp(sm) {
        font-size: rem(40);
      }

      @include bp(lg) {
        font-size: rem(54);
      }
    }

    &-image {
      position: relative;
      display: block;

      img {
        width: 100%;
        object-fit: cover;
        display: block;
      }
    }
  }

  &__image-credit {
    flex-basis: 32%;
    font-size: rem(9);
    text-align: right;
    font-family: $sans-serif-body-font;
    font-weight: $font-weight-normal;
    z-index: 1;
    text-transform: none;
  }
}
